<template>
    <div class="home-view">
        <bxs-toolbar
        depressed
        color="background">
            <img
            :src="$filters.toStaticFile($store.state.library.logo)"
            :alt="`Leila biblioteca degli oggetti ${$store.state.library ? $store.state.library.name : ''} logo`"
            style="max-width: 80px;">

            <bxs-spacer></bxs-spacer>

            <div class="bxs-truncate">
                <bxs-btn
                v-if="!$leila.auth.me"
                light
                to="/signin"
                color="black">Accedi</bxs-btn>

                <p
                v-else
                class="h5 mb-0 bxs-link"
                @click="$router.push('/me').catch(() => {})">Ciao <span>{{ $leila.auth.me.name.split(' ')[0] }}</span> <span class="h4">&#9996;</span></p>
            </div>
        </bxs-toolbar>

        <!-- welcome -->
        <home-banner />

        <search-box />

        <!-- highlighted products -->
        <highlighted-products-section />

        <headquarters-section />

        <signin-section />

        <!-- help -->
        <help-section />

        <!-- checkoutbar -->
        <checkoutbar to="/checkout/loan" />
    </div>
</template>

<script>
import HomeBanner from '@/components/project/HomeBanner.vue'
import SearchBox from '@/components/project/SearchBox.vue'

export default {
    name: 'home',
    components: {
        'home-banner': HomeBanner,
        'search-box': SearchBox
    },
    data () {
        return {
            loading: false,
            headquarters: []
        }
    }
}
</script>
